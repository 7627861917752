
.list {
   display: inline-block;
   position: relative;
}
.link {
   color: rgba(68, 66, 63, 1) !important;
}
.link:hover {
   color: black ;
}


.nav-menu{
   text-align: right;

   width: 100%;
   margin: 0;
   padding: 0;

}
.forMobileDiv{
   position: relative;
}
.logo{
   display: none;
}
@media screen and (max-width: 900px) {
   .navbar {
   z-index: 5;
display:block;
margin: 0 !important;
background-color: black !important;

}
.logoIcon{

      display: grid;
      grid-column-start: 1;
      grid-column-end: 2;
      grid-template-columns: auto auto;
      z-index: 1;
      
      width: 100%;

   }
   .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      position: absolute;
      top: 70px;
      left: -100%; 
      backdrop-filter: blur(3px);
      transition: all 0.5s ease;
      background-color: rgba(0, 0, 0, 0.5);
   }
   
   .nav-menu.active {
      margin-top: 70px;
      top: 0;
      left: 0;
      z-index: 1;
      transition: all 0.5s ease;
 
      
   }
   .link {
      width: 100%;
      display: table;
      color:white !important;
      font-size: 1.5rem !important;

   }
   .nav-link{

}

   .nav-icon3{
      display:grid;
    height: 30px;
   width: 40px;
margin-left: auto;
margin-right: 5%;
margin-top: 10%;
 -webkit-transform: rotate(0deg);
   -moz-transform: rotate(0deg);
   -o-transform: rotate(0deg);
   transform: rotate(0deg);
   -webkit-transition: .5s ease-in-out;
   -moz-transition: .5s ease-in-out;
   -o-transition: .5s ease-in-out;
   transition: .5s ease-in-out;
   cursor: pointer;
   z-index: 5;
   
   
}
.nav-icon3 span{
   display: block;
   position: absolute;
  height: 5px;
  width: 100%;
  background: white;
  border-radius: 9px;
  opacity: 1;
  left: 0;
 -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}
.nav-icon3 span:nth-child(1) {
   top: 0px;
 }
 
 .nav-icon3 span:nth-child(2),.nav-icon3 span:nth-child(3) {
   top: 12px;
 }
 
 .nav-icon3 span:nth-child(4) {
   top: 24px;
 }
 
 .nav-icon3.open span:nth-child(1) {
   top: 12px;
   width: 0%;
   left: 50%;
 }
 
 .nav-icon3.open span:nth-child(2) {
   -webkit-transform: rotate(45deg);
   -moz-transform: rotate(45deg);
   -o-transform: rotate(45deg);
   transform: rotate(45deg);
 }
 
 .nav-icon3.open span:nth-child(3) {
   -webkit-transform: rotate(-45deg);
   -moz-transform: rotate(-45deg);
   -o-transform: rotate(-45deg);
   transform: rotate(-45deg);
 }
 
 .nav-icon3.open span:nth-child(4) {
   top: 18px;
   width: 0%;
   left: 50%;
 }
 
}
