.nadpis {
   text-align: center;
   font-size: 40px;
}

.nadpis {
   margin-top: 25px;
   font-size: 30px;
}
.nadpis1{
   font-size: 30px;

}
.block {
   display: flex;
   flex-wrap: wrap;
   padding: 0 4px;
   padding-left: 5%;
   padding-right: 0%;
}

.containerImg img {
   margin: 10px;
   width: 250px;
   height: 350px;
   transition: all 0.3s;
   cursor: pointer;
}
.containerImg img:hover {
   transform: scale(1.1);
}

@media screen and (max-width: 900px) {
   .block {
      display: grid;
      grid-column: auto;
      grid-template-columns: auto;

      padding-left: 5%;
      padding-right: 0%;
   }
   .containerImg img {
      margin: 10px;
      width: 150px;
      height: 150px;
      transition: all 0.3s;
      cursor: pointer;
   }
}
