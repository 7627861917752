.nastenka {
   background-image: url('../album/exterier/nastěnka.jpg');
   background-repeat: no-repeat;
   image-rendering: pixelated;
   image-rendering: auto;
   image-rendering: crisp-edges;
   height: 300px;
   background-size: cover;
   background-position: 50% 35%;

}

@media screen and (max-width: 900px) {
   .nastenka{
     height: 200px;
   }

}
