.aktivity {
   padding-top: 50px;
   margin-left: 10%;
   margin-right: 10%;

   font-size: 25px;

   line-height: 50px;
}

.nadpis {
   font-size: 30px;
}

@media screen and (max-width: 900px) {
   .nadpis {
text-align: center;   }
   .aktivity {
      padding-top: 10px;
      margin-left: 10%;
      margin-right: 10%;
      font-size: 18px;
   
      line-height: 30px;
   }

}
