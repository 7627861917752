.aktivity {
    padding-top: 50px;
    margin-left: 5%;
    margin-right: 10%;
 
    font-size: 23px;
    text-align:justify;

    line-height: 50px;
 }

 .nadpis {
    font-size: 30px;
 }
 .h3{
     margin-top: 20px;
 }
 .mailto{
    text-decoration: none;

 }
 .mailto:hover{
    text-decoration: none;
 }
 @media screen and (max-width: 900px) {
   .aktivity {
      padding-top: 0;
      margin-left: 5%;
      margin-right: 10%;
   
      font-size: 20px;
      text-align:left;
  
      line-height: 40px;
      font-size: 1.2rem;

   }
   .first {
      
      border-bottom: none;
      padding-bottom: 0px;
   }
   .nadpis {
      text-align: center;
      font-size: 25px;
   }
   .h3{
      font-size: 1rem;
       margin-top: 0px;
   }
}

