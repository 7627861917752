.nastenka {
   background-image: url('../album/exterier/aktivity.png');
   background-repeat: no-repeat;
   image-rendering: pixelated;
   image-rendering: auto;
   image-rendering: crisp-edges;
   height: 300px;
   background-size: cover;
}

@media screen and (max-width: 900px) {
      .nastenka{
     height: 200px;
   }
 
}
