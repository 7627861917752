* {

   box-sizing: border-box;
}
body,

html {

   width: 100%;
   height: 100%;
}

.nastenka{
   position: relative;
   margin-top:0px;
   background-repeat: no-repeat;
   image-rendering: pixelated;
   image-rendering: auto;
   image-rendering: crisp-edges;
   background-size: cover;
/*    padding-right: 10%;
   padding-left: 10%; */
}
   .detailedNastenka {
   font-size: 60px;
   position: absolute;
   color: white;
   top: 50%;
   left: 30%;

}
@media screen and (max-width: 900px) {

   body,

html {
   

   width: 100%;
   height: 100%;
   padding-left: 0;
   padding-right: 0;
}
.image{
   padding-top: 0px;
   height: auto;
}
   .vse {
      padding-left: 0;
   }
   .nastenkos{
      position: relative;
      text-align: center;
      justify-content: center;
      align-items: center;
      
   }
   .obsazenost{
      padding-top: 0px;
   }
   .nastenka{
      position: relative;


      height: 200px;
      image-resolution: cover;
      margin-bottom: 0px;
      margin-top: 0px;

      
 
}
   .detailedNastenka {
 
      position: relative;
      margin-left: 0px;
      font-size: 40px;
left: 0;
right: 0;
    padding-top: 50px;
   border: none;
   }

}
